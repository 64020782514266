import React, { useState } from 'react';
import PortfolioPage from '../../components/PortfolioPage';
import MainImage from '../../images/portfolio/ViciousDogs/thumbnail.jpg';
import Progress_1 from '../../images/portfolio/ViciousDogs/VD_progress1.jpg';
import Progress_2 from '../../images/portfolio/ViciousDogs/VD_progress2.jpg';
import Progress_3 from '../../images/portfolio/ViciousDogs/VD_progress3.jpg';
import Progress_4 from '../../images/portfolio/ViciousDogs/VD_progress4.jpg';
const PortfolioCrispinGlover = () => {
    return <PortfolioPage img={MainImage} url="https://player.vimeo.com/video/558745578" title="Vicious Dogs" description={<p>
      “Vicious Dogs” er en bisarr film om en gutt på tur gjennom skogen, og hans møter med de forskjellige skapningene som lever der. Vi blir kastet inn i en verden som er uforutsigbar, fargerik, morsom og nådeløs. Filmen er en adapsjon av en fortelling skrevet av en 10-åring. Filmen benytter digitalt-håndanimerte, 2D-karakterer sammen med modellerte 3D-bakgrunner. 
      <br />
      <br />
      I filmen følger vi Per, en bryter som er på løpetur gjennom skogen. Seeren vet aldri hva som er rundt hjørnet for protagonisten, bestående av merkelige karakterer og forskjellige miljøer for han å forholde seg til. Derfor vil filmen kategoriseres som en eventyrfilm. 
      <br />
      <br />
      Filmen «Vicious Dogs» setter ut til å være eksemplet på hva som skjer når du adapterer en fortelling skrevet av et barn på en ikke-barnslig måte. Filmen er med andre ord ikke ment for barn, selv om den er skrevet av et. Fortellingen er visualisert ord for ord, og sparer ikke på detaljene. For eksempel, siden teksten er skrevet av et barn, så ignorerer teksten flere prinsipper man kan forvente å høre i en god tekst. Dette er ting som karakter-beskrivelser, mangel på forklaring av hvordan karakteren drar fra en plass til en annen, struktur og noen form for forsvarlig motivasjon innenfor karakterene. Resultatet er derfor en film som mangler alle disse prinsippene.
    </p>}>
         <div className="flex  mt-32 lg:px-0">
            <div className="max-w-sm">
            <img src={Progress_4} />
              <img src={Progress_2} />
              <img src={Progress_3} />
              
              <img src={Progress_1}  />
            </div>
          </div>

          <div className="flex flex-wrap justify-center mt-32 md:justify-between">

          </div>

    </PortfolioPage>

}

export default PortfolioCrispinGlover;